import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/ua/chapter_4/sub_10/slide1';
import Slide2 from 'src/slides/desktop/ua/chapter_4/sub_10/slide2.jsx';
import Slide3 from 'src/slides/desktop/ua/chapter_4/sub_10/slide3.jsx';

import WrapperMobile from 'src/slides/mobile/ua/chapter_4/sub_10/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from '../../../components/_shared/seo';

const allSlides = [
  <Slide1 />,
  <Slide2 />,
  <Slide3 />,
];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO
      title="Хто зламав більшовицькі шифри I Варшавська битва"
      description="Як гребінь і роман Едгара Алана По допомогли виграти Варшавську битву."
      lang="ua"
    />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO
      title="Хто зламав більшовицькі шифри I Варшавська битва"
      description="Як гребінь і роман Едгара Алана По допомогли виграти Варшавську битву."
      lang="ua"
    />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
